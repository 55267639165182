.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
  position: relative;
  overflow: auto;
  height: auto;
  min-width: 0;
  padding: 8px 0px;
  border-bottom: 0px none var(--token-LN1YkB123lXQ);
}
