.root {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  min-width: 0;
  border-radius: 10px;
  padding: 4px;
  border: 1px none #ff4d4f;
}
.rooterror {
  border-style: solid;
}
