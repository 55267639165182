.clientRow {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  min-width: 0;
  padding: 8px;
  border-top: 0px solid var(--token-Pxe4wDL2kJpb);
  border-right: 0px none var(--token-Pxe4wDL2kJpb);
  border-bottom: 1px solid #f0f0f0;
  border-left: 0px none var(--token-Pxe4wDL2kJpb);
}
.clientRow:hover {
  background: #f5f5f5;
}
.freeBox___6Y1Sw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
.freeBox__t8ZGg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
.freeBox__sUns2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
