.root {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  min-height: 0;
  padding: 0px;
}
.freeBox___1MsR {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.pageHeader:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-left: 16px;
  margin-top: 8px;
}
.freeBox__cdcZ {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.navigationBar:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__mbdEq {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: var(--token-LN1YkB123lXQ);
  min-width: 0;
  min-height: 0;
  padding: 8px 12px 12px;
}
.freeBox__mbdEq > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.freeBox__mbdEq > :global(.__wab_flex-container) > *,
.freeBox__mbdEq > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__mbdEq > :global(.__wab_flex-container) > picture > img,
.freeBox__mbdEq
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 12px;
}
.errorText {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: center;
  color: #ff4d4f;
  min-width: 0;
}
.freeBox___7GtO {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100vw;
  min-width: 0;
  padding: 0px 0px 8px;
}
.freeBox__lqeKh {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 90%;
  background: var(--token-jgohepLVeKvh);
  box-shadow: 0px 4px 16px 0px #00000033;
  min-width: 0;
  border-radius: 20px;
  padding: 8px;
}
.freeBox__lqeKh > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.freeBox__lqeKh > :global(.__wab_flex-container) > *,
.freeBox__lqeKh > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__lqeKh > :global(.__wab_flex-container) > picture > img,
.freeBox__lqeKh
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.text___66Nt {
  position: relative;
  width: 50px;
  height: auto;
  max-width: 100%;
  align-self: center;
  flex-shrink: 0;
}
.clientName {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: center;
  min-width: 0;
}
.text__jyNc8 {
  position: relative;
  width: 40px;
  height: auto;
  max-width: 100%;
  align-self: center;
  flex-shrink: 0;
}
.documentTypeWrapper:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 150px;
  flex-shrink: 0;
}
.documentType:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  align-self: center;
  width: 150px;
}
.text__cv9Mn {
  position: relative;
  width: 40px;
  height: auto;
  max-width: 100%;
  align-self: center;
  flex-shrink: 0;
}
.documentDateWrapper:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 150px;
  flex-shrink: 0;
}
.documentDate:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  width: 150px;
}
.text__hKlQi {
  position: relative;
  width: 50px;
  height: auto;
  max-width: 100%;
  align-self: center;
  flex-shrink: 0;
}
.documentStatus {
  position: relative;
  width: 80px;
  height: auto;
  max-width: 100%;
  align-self: center;
  flex-shrink: 0;
}
.saveButton:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  color: #ffffff;
  background: #327ce4;
}
.saveButtonText {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-bottom: 0px;
  min-width: 0;
}
.deleteButton:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  color: #ffffff;
  background: #ff4d4f;
}
.text__w2NgF {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-bottom: 0px;
  min-width: 0;
}
.freeBox__egE1M {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 50vh;
  max-width: 90%;
  background: var(--token-jgohepLVeKvh);
  box-shadow: 0px 4px 16px 0px #00000033;
  overflow: hidden;
  min-width: 0;
  flex-shrink: 0;
  border-radius: 20px;
  padding: 12px;
}
.freeBox___4KWAz {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.text___8Foc7 {
  width: 100%;
  height: auto;
  max-width: 100%;
  font-size: 28px;
  min-width: 0;
}
.freeBox__nUr2J {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  left: auto;
  top: auto;
  z-index: 1;
  right: auto;
  align-self: auto;
  padding: 8px;
}
.nameInputStack {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 0px;
}
.text__u8Pm {
  position: relative;
  width: 100px;
  height: auto;
  max-width: 100%;
  align-self: center;
  flex-shrink: 0;
}
.transcriptCoachNameWrapper:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 200px;
  flex-shrink: 0;
}
.transcriptCoachName:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  width: 200px;
}
.text___9E7Lb {
  position: relative;
  width: 100px;
  height: auto;
  max-width: 100%;
  align-self: center;
  margin-left: 8px;
  flex-shrink: 0;
}
.transcriptClientNameWrapper:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 200px;
  flex-shrink: 0;
}
.transcriptClientName:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  width: 200px;
}
.fileUpload:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  height: 33px;
  margin-top: 4px;
  margin-left: 8px;
}
.antButton:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  align-self: auto;
  left: auto;
  top: auto;
  z-index: 1;
  position: relative;
  right: auto;
  bottom: auto;
}
.text__iUaWe {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.documentText {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
  min-width: 0;
  min-height: 0;
  border-radius: 10px;
}
.freeBox__n03Ah {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 50vh;
  max-width: 90%;
  background: var(--token-jgohepLVeKvh);
  box-shadow: 0px 4px 16px 0px #00000033;
  width: 100%;
  margin-top: calc(0px + 12px) !important;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  min-width: 0;
  flex-shrink: 0;
  border-radius: 20px;
  padding: 8px 0px;
}
.text__mjkMc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-size: 28px;
  margin-left: 20px;
  min-width: 0;
}
.freeBox__bxr56 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.checkbox:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.text__tz8C6 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.checkbox2:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.text__uYiPv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.checkbox3:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.text__abWfo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__o2EtM {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #f0f0f0;
  min-width: 0;
  padding: 8px;
}
.text__gznX {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.text__xrKiS {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.text___7GPcs {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.text___4QVyn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.insightTable:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.deleteDocumentDialog:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.freeBox__qP4Cx {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
