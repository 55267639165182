.root {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  min-height: 0;
  padding: 8px 0px;
}
.pageHeader:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-left: 16px;
}
.freeBox__doaks {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.navigationBar:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__vQuDg {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: var(--token-LN1YkB123lXQ);
  min-width: 0;
  min-height: 0;
  padding: 12px;
}
.freeBox__vQuDg > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.freeBox__vQuDg > :global(.__wab_flex-container) > *,
.freeBox__vQuDg > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__vQuDg > :global(.__wab_flex-container) > picture > img,
.freeBox__vQuDg
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 12px;
}
.freeBox__sKJhZ {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px 0px;
}
.section {
  display: flex;
  position: relative;
  width: 100%;
  background: var(--token-jgohepLVeKvh);
  box-shadow: 0px 4px 16px 0px #00000033;
  flex-direction: row;
  max-width: 90%;
  min-width: 0;
  border-radius: 20px;
  padding: 8px;
}
.text__mOkpx {
  position: relative;
  width: 100px;
  height: auto;
  max-width: 100%;
  padding-left: 0px;
  flex-shrink: 0;
}
.clientName {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.text__nLw8X {
  position: relative;
  width: 60px;
  height: auto;
  max-width: 100%;
  flex-shrink: 0;
}
.clientEmail {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__kYjg4 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 50vh;
  max-width: 90%;
  background: var(--token-jgohepLVeKvh);
  box-shadow: 0px 4px 16px 0px #00000033;
  width: 100%;
  margin-top: calc(0px + 12px) !important;
  margin-right: 20px;
  margin-bottom: 0px;
  margin-left: 20px;
  min-width: 0;
  flex-shrink: 0;
  border-radius: 20px;
  padding: 8px 0px;
}
.freeBox__xaf4 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 10%;
  max-width: 100%;
  margin-left: 12px;
  min-width: 0;
  padding: 8px;
}
.text___9AeN9 {
  width: 100%;
  height: auto;
  max-width: 100%;
  font-size: 28px;
  min-width: 0;
}
.freeBox___15NEq {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 90%;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__atYaK {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox___9Sk8Q {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 0px 8px;
}
.button__bmNvn:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.text___2O0N {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-left: 0px;
  min-width: 0;
}
.freeBox__z19NW {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 5px;
  max-width: 100%;
  background: #327ce4;
  flex-shrink: 1;
  flex-grow: 0;
  min-width: 0;
  padding: 0px;
}
.freeBox__uLxFy {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 0px 8px;
}
.button__xXrhq:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.text__lI2C {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox___9VVt4 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 5px;
  max-width: 100%;
  background: #327ce4;
  flex-shrink: 1;
  flex-grow: 0;
  min-width: 0;
  padding: 0px;
}
.tabContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  flex-wrap: nowrap;
  align-content: stretch;
  min-width: 0;
  min-height: 0;
  padding: 8px;
}
.insightAggregatePanel:global(.__wab_instance) {
  position: relative;
  height: 100%;
  max-height: 100%;
}
.freeBox___4Sfvk {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  max-height: 100%;
  min-width: 0;
  padding: 0px;
}
.freeBox__sUlDo {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.checkbox:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.text__w5FKs {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.checkbox2:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.text__xFnxu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.checkbox3:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.text__rwoB2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox___46CFy {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #f0f0f0;
  min-width: 0;
  padding: 8px;
}
.text__abaxu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.text__wadXn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.text__qtiwp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.text___4Z0Am {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.insightTable:global(.__wab_instance) {
  position: relative;
  flex-shrink: 1;
}
.documentSection {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 90%;
  background: var(--token-zy6xhYnWF8Y7);
  box-shadow: 0px 4px 16px 0px #00000033;
  margin-top: calc(10px + 12px) !important;
  margin-left: 20px;
  margin-right: 20px;
  height: 50vh;
  min-width: 0;
  flex-shrink: 0;
  border-radius: 20px;
  padding: 8px 0px;
  border-width: 0px;
  border-style: none;
}
.freeBox__vh7Zy {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 8px;
  min-width: 0;
  padding: 0px;
}
.text__lW3Fn {
  width: 100%;
  height: auto;
  max-width: 100%;
  font-size: 28px;
  align-self: center;
  margin-left: 12px;
  min-width: 0;
}
.addDocumentButton:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  background: #327ce4;
  color: #ffffff;
  align-self: center;
  margin: 12px;
}
.text__p65Eg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.documentTableHeader {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #f0f0f0;
  min-width: 0;
  padding: 8px;
}
.text__u2Lsk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.text__tQ0Gf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.text__eb5Zr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.text___3GkMq {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.documentTable:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.insightDetailModal:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.freeBox__inSt {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
