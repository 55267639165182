.root {
  display: grid;
  position: relative;
  width: 100%;
  height: 100%;
  align-content: flex-start;
  justify-items: center;
  background: none;
  min-width: 0;
  min-height: 0;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
  padding: 8px;
}
.root > * {
  grid-column: 4;
}
@media (min-width: 0px) and (max-width: 768px) {
  .root > * {
    grid-column: 4;
  }
}
.freeBox__tAMe {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  padding: 0px;
}
.pageHeader:global(.__wab_instance) {
  position: relative;
}
.freeBox__soQs5 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  flex-grow: 0;
  flex-shrink: 0;
  min-height: none;
  max-height: none;
  padding: 0px;
  border: 1px none #da1a1a;
}
.navigationBar:global(.__wab_instance) {
  position: relative;
}
.freeBox__u5HGt {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: var(--token-LN1YkB123lXQ);
  min-width: 0;
  padding: 12px;
}
.freeBox__u5HGt > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.freeBox__u5HGt > :global(.__wab_flex-container) > *,
.freeBox__u5HGt > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__u5HGt > :global(.__wab_flex-container) > picture > img,
.freeBox__u5HGt
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 12px;
}
.clientSection {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 50%;
  max-width: 90%;
  background: var(--token-zy6xhYnWF8Y7);
  box-shadow: 0px 4px 16px 0px #00000033;
  margin-top: calc(10px + 12px) !important;
  min-width: 0;
  border-radius: 20px;
  padding: 8px 0px;
  border-width: 0px;
  border-style: none;
}
.text__ncIct {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-size: 28px;
  margin-left: 8px;
  min-width: 0;
}
.freeBox__voVt {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: auto;
  min-width: 0;
  padding: 8px;
}
.addClientButton:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.text__kl2BQ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.text__ud5Xp {
  width: auto;
  height: auto;
  max-width: 100%;
  text-align: right;
  flex-shrink: 1;
  flex-grow: 1;
  margin-left: 0px;
  align-self: auto;
  z-index: 1;
  right: auto;
  left: auto;
  top: auto;
  position: relative;
}
.input:global(.__wab_instance) {
  object-fit: cover;
  position: relative;
  flex-grow: 0;
  flex-shrink: 1;
  width: auto;
}
.clientTableHeader {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  height: auto;
  max-width: 100%;
  background: #f0f0f0;
  width: 100%;
  min-width: 0;
  padding: 8px;
}
.text__gooAj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.text___78Oi4 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.text__gwuGk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.clientTable:global(.__wab_instance) {
  position: relative;
}
.documentSection {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 50%;
  max-width: 90%;
  background: var(--token-zy6xhYnWF8Y7);
  box-shadow: 0px 4px 16px 0px #00000033;
  margin-top: calc(10px + 12px) !important;
  min-width: 0;
  border-radius: 20px;
  padding: 8px 0px;
  border-width: 0px;
  border-style: none;
}
.text__gNBiV {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-size: 28px;
  margin-left: 8px;
  min-width: 0;
}
.documentTableHeader {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #f0f0f0;
  min-width: 0;
  padding: 8px;
}
.text__c5Qfx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.text__pa4N7 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.text__iYek {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.text__vX1P3 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.documentTable:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.newClientDialog:global(.__wab_instance) {
  object-fit: cover;
  position: relative;
  width: 100%;
  grid-column-start: 3 !important;
  grid-column-end: -3 !important;
}
.freeBox__u5JE {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.freeBox__gkHmi {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.text___2Kjos {
  width: 50px;
  align-self: center;
  flex-shrink: 0;
}
.nameWrapper:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.name:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__eDbDb {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.text__aapG9 {
  width: 50px;
  align-self: center;
  flex-shrink: 0;
}
.freeBox__abQl0 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 4px;
}
.email:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.freeBox__t6S8J {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 22px;
  max-width: 100%;
  min-width: 0;
  flex-shrink: 0;
  padding: 0px;
}
.errorText {
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #ff4d4f;
  text-align: center;
  min-width: 0;
}
