.documentTable {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  overflow: auto;
  min-width: 0;
  padding: 8px 0px;
}
