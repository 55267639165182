.root {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: 100%;
  max-width: 100%;
  flex-grow: 0;
  flex-shrink: 1;
  position: relative;
  background: var(--token-LN1YkB123lXQ);
  min-height: 0;
  padding: 8px;
  border-right: 1px solid var(--token-Pxe4wDL2kJpb);
}
.home:global(.__wab_instance) {
  position: relative;
}
.svg__cAwWu {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  align-self: center;
  height: 1em;
}
.text__b8Xkw {
  align-self: auto;
  margin-left: 8px;
}
.settings:global(.__wab_instance) {
  position: relative;
}
.svg__o72Yc {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  align-self: center;
  flex-grow: 0;
  height: 1em;
}
.text___9ECB {
  margin-left: 8px;
}
