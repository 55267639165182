.root {
  display: grid;
  position: relative;
  width: 100%;
  height: 100%;
  align-content: flex-start;
  justify-items: center;
  min-width: 0;
  min-height: 0;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
  padding: 0px;
}
.root > * {
  grid-column: 4;
}
@media (min-width: 0px) and (max-width: 768px) {
  .root > * {
    grid-column: 4;
  }
}
.freeBox__lrVDz {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  min-height: 0;
  padding: 8px 0px;
}
.pageHeader:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-left: 16px;
}
.freeBox__mDd7F {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 100vh;
  max-width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  min-height: none;
  max-height: none;
  min-width: 0;
  padding: 0px;
  border: 1px none #da1a1a;
}
.navigationBar:global(.__wab_instance) {
  position: relative;
}
.freeBox__dNh1 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  top: auto;
  left: auto;
  z-index: 1;
  align-self: auto;
  min-width: 0;
  padding: 8px;
}
.text {
  width: auto;
  height: auto;
  max-width: 100%;
  align-self: auto;
  font-size: 46px;
}
