.root {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  min-width: 0;
  padding: 8px;
}
.img {
  position: relative;
  object-fit: contain;
  max-width: 100%;
  width: 32px;
  height: 32px;
  margin-top: 8px;
  margin-right: 8px;
  flex-shrink: 0;
}
.img > picture > img {
  object-fit: contain;
}
.text {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-size: 32px;
  min-width: 0;
}
