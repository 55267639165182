.root {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  cursor: pointer;
  min-width: 0;
  padding: 8px;
}
.rootselected {
  border-width: 1px;
  border-style: solid;
}
.svg___6Z9Dk {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
