.root {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  min-width: 0;
  padding: 0px 8px 8px;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 8px;
}
.freeBox__xbodf {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 4px 16px 0px #00000033;
  min-width: 0;
  border-radius: 10px;
  padding: 8px 0px;
  border: 1px solid #f0f0f0;
}
.text___7Cv49 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: center;
  margin-bottom: 8px;
  min-width: 0;
}
.freeBox__uuCta {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #f0f0f0;
  min-width: 0;
  padding: 8px;
}
.text___8T2B {
  position: relative;
  width: 70%;
  height: auto;
}
.text__vljZq {
  position: relative;
  width: 30%;
  height: auto;
}
.strengthsTable:global(.__wab_instance) {
  position: relative;
  height: auto;
}
.freeBox__bZ4Gl {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 4px 16px 0px #00000033;
  min-width: 0;
  border-radius: 10px;
  padding: 8px 0px;
  border: 1px solid #f0f0f0;
}
.text__nfntY {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: center;
  margin-bottom: 8px;
  min-width: 0;
}
.freeBox___5MUae {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #f0f0f0;
  min-width: 0;
  padding: 8px;
}
.text__jnObC {
  position: relative;
  width: 70%;
  height: auto;
}
.text___5AUeg {
  position: relative;
  width: 30%;
  height: auto;
}
.valuesTable:global(.__wab_instance) {
  position: relative;
}
.freeBox__rsQf {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 4px 16px 0px #00000033;
  min-width: 0;
  border-radius: 10px;
  padding: 8px 0px;
  border: 1px solid #f0f0f0;
}
.text__icZow {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: center;
  margin-bottom: 8px;
  min-width: 0;
}
.freeBox__kr3Jm {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #f0f0f0;
  min-width: 0;
  padding: 8px;
}
.text__prlWi {
  position: relative;
  width: 70%;
  height: auto;
}
.text__reXXp {
  position: relative;
  width: 30%;
  height: auto;
}
.themesTable:global(.__wab_instance) {
  position: relative;
}
.insightDetailModal:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.freeBox__oqj68 {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
