.root {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  min-height: 0;
  padding: 8px;
}
.freeBox {
  display: block;
  flex-direction: column;
  width: 700px;
  height: 900px;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
}
.img {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  left: 14px;
  top: 88px;
  width: 820px;
  margin: 0px;
}
.img > picture > img {
  object-fit: cover;
}
.text__uFzux {
  position: absolute;
  width: auto;
  height: auto;
  max-width: 100%;
  left: 279px;
  top: 143px;
}
.h1__d24EY {
  width: 187px;
}
.text__ulEv {
  position: absolute;
  width: auto;
  height: auto;
  max-width: 100%;
  left: 307px;
  top: 688px;
}
