.root {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: #f0f0f0;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  min-height: 0;
  padding: 24px 96px 8px;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 24px;
}
.freeBox__zlaYa {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #ffffff;
  min-width: 0;
  border-radius: 10px;
  padding: 24px 8px;
}
.freeBox__zlaYa > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.freeBox__zlaYa > :global(.__wab_flex-container) > *,
.freeBox__zlaYa > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__zlaYa > :global(.__wab_flex-container) > picture > img,
.freeBox__zlaYa
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
.text__qUggm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.h1 {
  text-align: center;
}
.text___6BdtB {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.h3 {
  text-align: center;
}
.text__cDpfb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: center;
  min-width: 0;
}
.freeBox__drfWv {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #ffffff;
  min-width: 0;
  border-radius: 10px;
  padding: 24px 8px;
}
.img___0TwT {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 810px;
  flex-shrink: 0;
}
.img___0TwT > picture > img {
  object-fit: cover;
}
.freeBox___7GZzW {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.text__apSom {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.text__rr2Eh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  margin: 12px;
}
.freeBox__lYx6C {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #ffffff;
  min-width: 0;
  border-radius: 10px;
  padding: 24px 8px;
}
.freeBox__nxat7 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-right: 8px;
  min-width: 0;
  padding: 8px;
}
.text__hBx4W {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.h4__bt7Rl {
  text-align: right;
}
.text__uH40K {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: right;
  min-width: 0;
  margin: 12px;
}
.img__q0Qj {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 546px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
  margin-right: 96px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid var(--token-Pxe4wDL2kJpb);
}
.img__q0Qj > picture > img {
  object-fit: cover;
}
.goalAndActionTable {
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  box-shadow: none;
  margin-right: 96px;
  margin-left: 0px;
  display: none;
  padding: 0px;
  border: 1px none var(--token-LN1YkB123lXQ);
}
.freeBox___4NphW {
  display: flex;
  position: relative;
  flex-direction: row;
  width: auto;
  height: auto;
  max-width: 100%;
  background: var(--token-LN1YkB123lXQ);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding: 0px 8px;
}
.freeBox___4NphW > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.freeBox___4NphW > :global(.__wab_flex-container) > *,
.freeBox___4NphW > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___4NphW > :global(.__wab_flex-container) > picture > img,
.freeBox___4NphW
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 12px;
}
.freeBox__dDoRi {
  position: relative;
  width: 300px;
  height: 100%;
  max-width: 100%;
  align-self: center;
  background: none;
  justify-content: center;
  display: flex;
  flex-direction: row;
  border-right-style: solid;
  border-right-width: 1px;
  flex-shrink: 0;
  min-height: 0;
}
.text__cfpc7 {
  position: relative;
  text-align: center;
  height: 19px;
  align-self: center;
}
.text__geMsu {
  position: relative;
  width: 45px;
  height: auto;
  max-width: 100%;
  border-right-style: solid;
  border-right-width: 1px;
  padding-right: 8px;
  flex-shrink: 0;
}
.text___56GUf {
  position: relative;
  width: 45px;
  height: auto;
  max-width: 100%;
  border-right-style: solid;
  border-right-width: 1px;
  padding-right: 8px;
  flex-shrink: 0;
}
.text__le9E9 {
  position: relative;
  width: 45px;
  height: auto;
  max-width: 100%;
  border-right-style: solid;
  border-right-width: 1px;
  padding-right: 8px;
  flex-shrink: 0;
}
.text__l7Xls {
  position: relative;
  width: 45px;
  height: auto;
  max-width: 100%;
  flex-shrink: 0;
}
.freeBox__f1HkB {
  display: flex;
  position: relative;
  flex-direction: row;
  width: auto;
  height: auto;
  max-width: 100%;
  background: none;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding: 0px 8px;
}
.freeBox__f1HkB > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.freeBox__f1HkB > :global(.__wab_flex-container) > *,
.freeBox__f1HkB > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__f1HkB > :global(.__wab_flex-container) > picture > img,
.freeBox__f1HkB
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 12px;
}
.freeBox__jdul9 {
  position: relative;
  width: 300px;
  height: 100%;
  max-width: 100%;
  align-self: center;
  background: none;
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
  border-right-style: solid;
  border-right-width: 1px;
  flex-shrink: 0;
  min-height: 0;
}
.text__d3M7Z {
  position: relative;
  text-align: center;
  height: 19px;
  align-self: center;
}
.text__nvvKn {
  position: relative;
  width: 45px;
  height: auto;
  max-width: 100%;
  border-right-style: solid;
  border-right-width: 1px;
  padding-right: 8px;
  flex-shrink: 0;
}
.text__dw8Lb {
  position: relative;
  width: 45px;
  height: auto;
  max-width: 100%;
  border-right-style: solid;
  border-right-width: 1px;
  padding-right: 8px;
  flex-shrink: 0;
}
.text___1Xjgt {
  position: relative;
  width: 45px;
  height: auto;
  max-width: 100%;
  border-right-style: solid;
  border-right-width: 1px;
  padding-right: 8px;
  flex-shrink: 0;
}
.text___1JztQ {
  position: relative;
  width: 45px;
  height: auto;
  max-width: 100%;
  flex-shrink: 0;
}
.freeBox__eXdx1 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: auto;
  height: auto;
  max-width: 100%;
  background: none;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding: 0px 8px;
}
.freeBox__eXdx1 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.freeBox__eXdx1 > :global(.__wab_flex-container) > *,
.freeBox__eXdx1 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__eXdx1 > :global(.__wab_flex-container) > picture > img,
.freeBox__eXdx1
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 12px;
}
.freeBox__vnBLm {
  position: relative;
  width: 300px;
  height: 100%;
  max-width: 100%;
  align-self: center;
  background: none;
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  border-right-style: solid;
  border-right-width: 1px;
  flex-shrink: 0;
  min-height: 0;
}
.text___8KiPn {
  position: relative;
  text-align: center;
  height: 19px;
  align-self: center;
  padding-right: 12px;
}
.freeBox__eS1Qw {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 45px;
  height: 20px;
  max-width: 100%;
  border-right-style: solid;
  border-right-width: 1px;
  flex-shrink: 0;
  padding: 0px 8px;
}
.checkbox:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  border-right-style: none;
  width: auto;
  border-right-width: 1px;
  height: 20px;
}
.text___8Xuci {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__qIoRr {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 45px;
  height: 20px;
  max-width: 100%;
  border-right-style: solid;
  border-right-width: 1px;
  flex-shrink: 0;
  padding: 0px 8px;
}
.checkbox5:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  border-right-style: none;
  width: auto;
  border-right-width: 1px;
  height: 20px;
}
.text__g873X {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__bMUk {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 45px;
  height: 20px;
  max-width: 100%;
  border-right-style: solid;
  border-right-width: 1px;
  flex-shrink: 0;
  padding: 0px 8px;
}
.checkbox6:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  border-right-style: none;
  width: auto;
  border-right-width: 1px;
  height: 20px;
}
.text__eAAzx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__xkUzc {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 45px;
  height: 20px;
  max-width: 100%;
  border-right-style: none;
  border-right-width: 1px;
  flex-shrink: 0;
  padding: 0px 8px;
}
.checkbox7:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  border-right-style: none;
  width: auto;
  border-right-width: 1px;
  height: 20px;
}
.text__g0J60 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox___8Qo72 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: auto;
  height: auto;
  max-width: 100%;
  background: none;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding: 0px 8px;
}
.freeBox___8Qo72 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.freeBox___8Qo72 > :global(.__wab_flex-container) > *,
.freeBox___8Qo72 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___8Qo72 > :global(.__wab_flex-container) > picture > img,
.freeBox___8Qo72
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 12px;
}
.freeBox__axsVz {
  position: relative;
  width: 300px;
  height: 100%;
  max-width: 100%;
  align-self: center;
  background: none;
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  border-right-style: solid;
  border-right-width: 1px;
  flex-shrink: 0;
  min-height: 0;
}
.text__x2Qxe {
  position: relative;
  text-align: center;
  height: 19px;
  align-self: center;
  padding-right: 12px;
}
.freeBox__o3Z7M {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 45px;
  height: 20px;
  max-width: 100%;
  border-right-style: solid;
  border-right-width: 1px;
  flex-shrink: 0;
  padding: 0px 8px;
}
.checkbox2:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  border-right-style: none;
  width: auto;
  border-right-width: 1px;
  height: 20px;
}
.text__q6Cp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__unwjT {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 45px;
  height: 20px;
  max-width: 100%;
  border-right-style: solid;
  border-right-width: 1px;
  flex-shrink: 0;
  padding: 0px 8px;
}
.checkbox8:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  border-right-style: none;
  width: auto;
  border-right-width: 1px;
  height: 20px;
}
.text__tYaYp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__hNr9W {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 45px;
  height: 20px;
  max-width: 100%;
  border-right-style: solid;
  border-right-width: 1px;
  flex-shrink: 0;
  padding: 0px 8px;
}
.checkbox9:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  border-right-style: none;
  width: auto;
  border-right-width: 1px;
  height: 20px;
}
.text___27Fl {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__ucVrB {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 45px;
  height: 20px;
  max-width: 100%;
  border-right-style: none;
  border-right-width: 1px;
  flex-shrink: 0;
  padding: 0px 8px;
}
.checkbox10:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  border-right-style: none;
  width: auto;
  border-right-width: 1px;
  height: 20px;
}
.text___8HtQd {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__l0Gk4 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: auto;
  height: auto;
  max-width: 100%;
  background: none;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding: 0px 8px;
}
.freeBox__l0Gk4 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.freeBox__l0Gk4 > :global(.__wab_flex-container) > *,
.freeBox__l0Gk4 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__l0Gk4 > :global(.__wab_flex-container) > picture > img,
.freeBox__l0Gk4
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 12px;
}
.freeBox__bpGf {
  position: relative;
  width: 300px;
  height: 100%;
  max-width: 100%;
  align-self: center;
  background: none;
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
  border-right-style: solid;
  border-right-width: 1px;
  flex-shrink: 0;
  min-height: 0;
}
.text___7Trp {
  position: relative;
  text-align: center;
  height: 19px;
  align-self: center;
}
.text__pwxLf {
  position: relative;
  width: 45px;
  height: auto;
  max-width: 100%;
  border-right-style: solid;
  border-right-width: 1px;
  padding-right: 8px;
  flex-shrink: 0;
}
.text__lephF {
  position: relative;
  width: 45px;
  height: auto;
  max-width: 100%;
  border-right-style: solid;
  border-right-width: 1px;
  padding-right: 8px;
  flex-shrink: 0;
}
.text__aBeuk {
  position: relative;
  width: 45px;
  height: auto;
  max-width: 100%;
  border-right-style: solid;
  border-right-width: 1px;
  padding-right: 8px;
  flex-shrink: 0;
}
.text__xxay {
  position: relative;
  width: 45px;
  height: auto;
  max-width: 100%;
  flex-shrink: 0;
}
.freeBox__h6VxG {
  display: flex;
  position: relative;
  flex-direction: row;
  width: auto;
  height: auto;
  max-width: 100%;
  background: none;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding: 0px 8px;
}
.freeBox__h6VxG > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.freeBox__h6VxG > :global(.__wab_flex-container) > *,
.freeBox__h6VxG > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__h6VxG > :global(.__wab_flex-container) > picture > img,
.freeBox__h6VxG
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 12px;
}
.freeBox__fzLH {
  position: relative;
  width: 300px;
  height: 100%;
  max-width: 100%;
  align-self: center;
  background: none;
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  border-right-style: solid;
  border-right-width: 1px;
  flex-shrink: 0;
  min-height: 0;
}
.text___1MQd8 {
  position: relative;
  text-align: center;
  height: 19px;
  align-self: center;
  padding-right: 12px;
}
.freeBox__pmZmt {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 45px;
  height: 20px;
  max-width: 100%;
  border-right-style: solid;
  border-right-width: 1px;
  flex-shrink: 0;
  padding: 0px 8px;
}
.checkbox3:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  border-right-style: none;
  width: auto;
  border-right-width: 1px;
  height: 20px;
}
.text__kckXm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__h9Dgb {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 45px;
  height: 20px;
  max-width: 100%;
  border-right-style: solid;
  border-right-width: 1px;
  flex-shrink: 0;
  padding: 0px 8px;
}
.checkbox11:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  border-right-style: none;
  width: auto;
  border-right-width: 1px;
  height: 20px;
}
.text__jkWkC {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__odxhi {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 45px;
  height: 20px;
  max-width: 100%;
  border-right-style: solid;
  border-right-width: 1px;
  flex-shrink: 0;
  padding: 0px 8px;
}
.checkbox12:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  border-right-style: none;
  width: auto;
  border-right-width: 1px;
  height: 20px;
}
.text__o68Qj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__bMVaw {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 45px;
  height: 20px;
  max-width: 100%;
  border-right-style: none;
  border-right-width: 1px;
  flex-shrink: 0;
  padding: 0px 8px;
}
.checkbox13:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  border-right-style: none;
  width: auto;
  border-right-width: 1px;
  height: 20px;
}
.text___9Co0J {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox___3PxfK {
  display: flex;
  position: relative;
  flex-direction: row;
  width: auto;
  height: auto;
  max-width: 100%;
  background: none;
  border-bottom-style: none;
  border-bottom-width: 1px;
  padding: 0px 8px;
}
.freeBox___3PxfK > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.freeBox___3PxfK > :global(.__wab_flex-container) > *,
.freeBox___3PxfK > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___3PxfK > :global(.__wab_flex-container) > picture > img,
.freeBox___3PxfK
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 12px;
}
.freeBox__jj80 {
  position: relative;
  width: 300px;
  height: 100%;
  max-width: 100%;
  align-self: center;
  background: none;
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  border-right-style: solid;
  border-right-width: 1px;
  flex-shrink: 0;
  min-height: 0;
}
.text__nCHoj {
  position: relative;
  text-align: center;
  height: 19px;
  align-self: center;
  padding-right: 12px;
}
.freeBox__kRbQv {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 45px;
  height: 20px;
  max-width: 100%;
  border-right-style: solid;
  border-right-width: 1px;
  flex-shrink: 0;
  padding: 0px 8px;
}
.checkbox4:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  border-right-style: none;
  width: auto;
  border-right-width: 1px;
  height: 20px;
}
.text__ppAm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__eTgbw {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 45px;
  height: 20px;
  max-width: 100%;
  border-right-style: solid;
  border-right-width: 1px;
  flex-shrink: 0;
  padding: 0px 8px;
}
.checkbox14:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  border-right-style: none;
  width: auto;
  border-right-width: 1px;
  height: 20px;
}
.text___2Ca3V {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__a8PB6 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 45px;
  height: 20px;
  max-width: 100%;
  border-right-style: solid;
  border-right-width: 1px;
  flex-shrink: 0;
  padding: 0px 8px;
}
.checkbox15:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  border-right-style: none;
  width: auto;
  border-right-width: 1px;
  height: 20px;
}
.text__nttMk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox___8Umf {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 45px;
  height: 20px;
  max-width: 100%;
  border-right-style: none;
  border-right-width: 1px;
  flex-shrink: 0;
  padding: 0px 8px;
}
.checkbox16:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  border-right-style: none;
  width: auto;
  border-right-width: 1px;
  height: 20px;
}
.text__u2UTo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__mJut4 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #ffffff;
  min-width: 0;
  border-radius: 10px;
  padding: 24px 8px;
}
.img___3DgBs {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 416px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
  margin-left: 124px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid var(--token-Pxe4wDL2kJpb);
}
.img___3DgBs > picture > img {
  object-fit: cover;
}
.freeBox__zb9A {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 20px;
  min-width: 0;
  padding: 8px;
}
.text___2FXY {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.text__kqeNt {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-left: 0px;
  min-width: 0;
  margin: 12px;
}
.freeBox__yIddi {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #ffffff;
  min-width: 0;
  border-radius: 10px;
  padding: 24px 8px;
}
.img__m47ZL {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 397px;
}
.img__m47ZL > picture > img {
  object-fit: cover;
}
.freeBox__ggaQr {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 65%;
  height: auto;
  max-width: 100%;
  padding: 8px;
}
.text__nm6LB {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.h4__nmenY {
  text-align: left;
  margin-top: 40px;
}
.text__obQhy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: left;
  min-width: 0;
  margin: 12px;
}
.freeBox__lcCso {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #ffffff;
  min-width: 0;
  border-radius: 10px;
  padding: 24px 8px;
}
.freeBox__r4YCa {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-right: 20px;
  margin-top: 96px;
  min-width: 0;
  padding: 8px;
}
.text__pyV9G {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.h4__dbH5 {
  text-align: right;
}
.text__qjs1O {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: right;
  min-width: 0;
  margin: 12px;
}
.img__tzHgC {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 541px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
  margin-right: 96px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid var(--token-Pxe4wDL2kJpb);
}
.img__tzHgC > picture > img {
  object-fit: cover;
}
.freeBox__xkanh {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #ffffff;
  min-width: 0;
  border-radius: 10px;
  padding: 24px 8px;
}
.img__l4UhN {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
  margin-left: 96px;
  border-radius: 10px;
  border: 1px solid var(--token-Pxe4wDL2kJpb);
}
.img__l4UhN > picture > img {
  object-fit: cover;
}
.freeBox___0SpLj {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px 8px 8px 20px;
}
.text__gIHnQ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.h4__uVuca {
  margin-top: 20px;
  text-align: left;
}
.text__aSw2C {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: left;
  min-width: 0;
  margin: 12px;
}
